import { useState } from 'react';
import { headers, usefetch } from '../helpers/fetch';

export const useGenerateTest = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [customQuesPopup, setCustomQuesPopup] = useState(false);
  const [isGeneratingTest, setIsGeneratingTest] = useState(null);

  const generateTest = async (jid) => {
    setIsGeneratingTest(true);
    setLoading(true);
    const data = {
      jid: jid,
    };

    const resp = await usefetch(
      'testBuilder/generatetest',
      'POST',
      headers(),
      data,
    );

    if (resp?.code === '11') {
      if (resp?.data?.mcqs?.length > 0) {
        setQuestions((prev) => [...resp?.data?.mcqs]);
      }
      setLoading(false);
    }

    if (!resp.ok) {
      setError(resp.error);
      setLoading(false);
    }

    if (resp.ok) {
      setResponse((prevData) => [...prevData, resp]);
      setLoading(false);
    }

    setIsGeneratingTest(false);

    return resp;
  };

  return {
    loading,
    generateTest,
    response,
    questions,
    isGeneratingTest,
    error,
    customQuesPopup,
    setCustomQuesPopup,
  };
};
