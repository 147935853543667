import { regexForLeadingAndTrailingSpaces } from './strings';

export const jobTitleValidation = (value) => {
  const error = value.match(/^.{2,50}$/) ? null : 'Character limit 2-50';
  return error;
};

export const manageEmpValidation = (value) => {
  const error = value.match(/^(?:Yes|No|yes|no|YES|NO)$/)
    ? null
    : 'Value should be Yes or No';
  return error;
};

export const subordinateValidation = (value) => {
  const error = value?.length < 1 ? 'This field is required' : null;
  return error;
};

export const maxBudgetValidation = (value) => {
  const error = value?.length < 1 ? 'This field is required' : null;
  return error;
};

export const requireStringValidation = (value) => {
  const error = value.match(/^.{2,50}$/) ? null : 'Enter a valid value';
  return error;
};

export const emailValidation = (value) => {
  if (value.includes('|')) {
    // for administrator login
    return null;
  }
  const error = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ? null
    : 'Email is not valid';
  return error;
};

export const phoneNumberValidation = (val) => {
  const cleanedVal = val?.replace(/[^0-9]/g, ''); // Remove all non-digit characters
  const error =
    cleanedVal.length < 11 || cleanedVal.length > 16
      ? 'Please enter a valid phone number (11-16 digits)'
      : null;
  return error;
};

export const passwordValidation = (value) => {
  let error = '';
  if (value?.length < 8) {
    error = 'Password length must be of 8 characters.';
  }
  if (!/\d/.test(value)) {
    error = 'Password should contain at least one digit.';
  }
  if (!/[A-Za-z]/.test(value)) {
    error = 'Password should contain at least one alphabet.';
  }
  return error;
};
